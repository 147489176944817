import { defineNuxtRouteMiddleware } from "#imports";
import { useWebApp } from "vue-tg";
import { getRemoteConfig, getSessionById } from "~/services/api/session/api";

export default defineNuxtRouteMiddleware(async (to, from) => {
  if (to.meta.guest) {
    return;
  }

  const appSetting = useAppSetting();
  const setting = useSettingStore();

  setting.setSetting("project_id", to.query.project_id || "");
  localStorage.setItem("project_id", (to.query.project_id as string) || "");

  try {
    const startParams = (useWebApp().initDataUnsafe.start_param || to.query.tgWebAppStartParam) as string;

    if (!startParams) {
      return;
    }

    const parseParams = new URLSearchParams(decodeURIComponent(startParams.replace("__", "&")));
    const sid = parseParams.get("sid");
    localStorage.setItem("stavax_sid", sid || "");

    if (!sid) {
      return;
    }
    appSetting.saveSid(sid);

    const savedSession = localStorage.getItem("stavax_session_data");
    const parseSession = JSON.parse(savedSession || "{}");

    const session = parseSession.id === sid ? parseSession.session : await getSessionById(sid);

    localStorage.setItem(
      "stavax_session_data",
      JSON.stringify({
        id: sid,
        session: session,
      })
    );
    console.log("session", session);
    setting.setSetting("project_id", session?.project_id || "");
    localStorage.setItem("project_id", session?.project_id || "");

    if (session) {
      const session_data = session.data;
      setting.setSetting("sessionInfo", session_data);
      if (session_data.href) appSetting.changeNextHref(session_data.href);
    }
  } catch (err) {
    console.log(err);
  }
});
