import { toast } from "~/components/ui/toast";
import Fetch from "..";
import errorHandler from "../ErrorHandler";
import { IUserWallet } from "../auth/type";

export async function postImportWallet(address: string, name: string): Promise<IUserWallet[] | null> {
  try {
    const { data } = await Fetch.post<{ data: IUserWallet[] }>(`@api/user-wallets/import`, {
      address,
      name,
    });
    return data.data;
  } catch (error) {
    console.error("postImportWallet er", error.response.status);
    toast({
      description: errorHandler(error).getAllMessagesString(),
      duration: 3000,
    });
    return null;
  }
}

export async function postRenameWallet(address: string, name: string): Promise<IUserWallet | null> {
  try {
    const { data } = await Fetch.post<{ data: IUserWallet }>(`@api/user-wallets/edit-name`, {
      address,
      name,
    });
    return data.data;
  } catch (error) {
    console.error("postRenameWallet er", error.response.status);
    toast({
      description: errorHandler(error).getAllMessagesString(),
      duration: 3000,
    });
    return null;
  }
}
