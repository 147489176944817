import { defineNuxtRouteMiddleware } from "#imports";
import { useWebApp } from "vue-tg";
import { authTelegram, getUserInfo, loginByProject } from "~/services/api/auth/api";
import { getRemoteConfig } from "~/services/api/session/api";
import PasswordService from "~/services/security/PasswordService";
import { useWalletStore } from "~/services/WalletManager/store";
import { useAuthStore } from "~/stores/auth";

const requiredAuthLayouts = ["dashboard", "bottom-tab", "onboard"];

export default defineNuxtRouteMiddleware(async (to, from) => {
  if (!to.meta.layout || to.meta.guest || !requiredAuthLayouts.includes(to.meta.layout)) return;
  const { setAccessToken, getAccessToken, setUser, getUser } = useAuthStore();

  const webApp = useWebApp();
  const initData = webApp.initData;
  if (initData && !getAccessToken()) {
    if (to.path === "/onboard") return;

    const res =
      webApp.platform === "stv_iframe"
        ? await loginByProject("telegram", {
            init_data: initData,
          })
        : await authTelegram(initData);
    if (res.status === 403 && webApp.platform === "stv_iframe") {
      return navigateTo("/onboard/allowance", {
        replace: true,
      });
    }

    if (res && res.access_token) {
      setAccessToken(res.access_token);
    } else {
      return navigateTo("/onboard", {
        replace: true,
      });
    }
  }

  let userInfo = getUser();
  if (!userInfo?.id && getAccessToken()) {
    userInfo = await getUserInfo();
    setUser(userInfo);
  }

  if (!userInfo.id) {
    const query = { provider: "telegram", token: initData };
    return navigateTo({ query, path: "/onboard" }, { replace: true });
  }
  const mpcStore = useMPCStore(userInfo.id);

  if (userInfo.id && !mpcStore.isUnlocked) {
    await mpcStore.unlock(userInfo.id);
  }

  const wallets = useWalletStore();

  await wallets.init();

  console.log("auth middleware", to.path);

  if ((userInfo?.smart_wallets?.length || 0) > 0) {
    let checkExpire = false;

    userInfo.smart_wallets?.forEach((w) => {
      if (!w.blockchain) return;
      const wallet = wallets.findWalletWithAddress(w.smart_address);
      if (!wallet) {
        // khong co wallet o device nay
        checkExpire = true;
      } else if (wallet.address !== w.eoa_address) {
        //  co wallet nhung da khac server
        checkExpire = true;
        // wallet.clear(userInfo.id);
      }
    });
    if (checkExpire) {
      if (mpcStore.isLocked) {
        await mpcStore.unlock(userInfo.id);
      }
      await mpcStore.clear(userInfo.id);
      const mpcWallet = wallets.findWalletByType("evm");
      if (mpcWallet) {
        await mpcWallet.getSavedWallet();
        checkExpire = false;
        userInfo.smart_wallets?.forEach((w) => {
          if (!w.blockchain) return;
          const wallet = wallets.findWalletWithAddress(w.smart_address);
          if (!wallet) {
            // khong co wallet o device nay
            checkExpire = true;
          } else if (wallet.address !== w.eoa_address) {
            //  co wallet nhung da khac server
            checkExpire = true;
            // wallet.clear(userInfo.id);
          }
        });
      }
    }
    console.log("check mpc", wallets.existMPCWallet, mpcStore.isEmpty, checkExpire);
    if (wallets.existMPCWallet && (mpcStore.isEmpty || checkExpire)) {
      if (to.path === "/onboard/restore") return;
      return navigateTo("/onboard/restore", {
        replace: true,
      });
    }
    const pwd = PasswordService.getPassword();
    if (!pwd && !to.path.startsWith("/onboard/sign-in")) {
      return navigateTo("/onboard/sign-in", {
        replace: true,
      });
    }
    const setting = useSettingStore();
    if (setting.getSetting().remoteConfig.supported_networks) {
      getRemoteConfig().then((res) => {
        setting.setSetting("remoteConfig", res);
      });
    } else {
      const config = await getRemoteConfig();
      setting.setSetting("remoteConfig", config);
    }

    // if (to.path.startsWith("/onboard")) return;
    //TODO: remove after update bot webapp url
    if (to.path.startsWith("/telegram")) {
      return navigateTo("/", {
        replace: true,
      });
    }
    return;
  }

  if (to.path.startsWith("/onboard")) return;
  if (to.path.startsWith("/account/upgrade-wallet")) return;
  const query = { provider: "telegram", token: initData };
  return navigateTo({ query, path: "/onboard" }, { replace: true });
});
