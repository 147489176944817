<script setup lang="ts">
import { cn } from "~/lib/utils";

const model = defineModel();

const props = defineProps<{
  placeholder: string;
  type?: "text" | "password";
  label?: string;
  defaultValue?: string;
  labelClass?: string;
  disabled?: boolean;
  hint?: string;
  onEntered?: () => void;
}>();

const typeState = ref(props.type || "text");

const showPwd = ref(false);

onMounted(() => {
  model.value = props.defaultValue;
});

function onClick() {
  showPwd.value = !showPwd.value;
  if (showPwd.value) {
    typeState.value = "text";
  } else {
    typeState.value = props.type || "text";
  }
}
</script>

<template>
  <div class="w-full">
    <p v-if="label" :class="cn('text-[14px] text-[#f6f6f6] text-start font-[600] mb-4', labelClass)">{{ label }}</p>
    <div class="relative">
      <input
        class="p-3 bg-transparent rounded-[16px] border border-[rgba(255,255,255,0.2)] w-full"
        :class="type === 'password' ? 'pr-10' : ''"
        :placeholder="placeholder"
        v-model="model"
        :type="typeState"
        :disabled="disabled"
        @keyup.enter="onEntered"
      />
      <div v-if="!!hint" class="text-start mt-2 p-3 bg-[#292929] text-[#888] rounded-[12px]">
        <p>hint: {{ hint }}</p>
      </div>
      <button class="absolute top-[14px] right-[12px] cursor-pointer" @click="onClick">
        <img v-if="type === 'password'" :src="showPwd ? '/images/icon-hide-pwd.svg' : '/images/icon-show-pwd.svg'" />
      </button>
    </div>
  </div>
</template>
